import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../../assets/logo/logo.png";

export class NavBar extends Component {
  state = {
    color: "transparent",
  };

  listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
    } else {
    }
  };

  componentDidMount() {
    window.addEventListener("scroll", this.listenScrollEvent);
  }

  render() {
    let activePath = this.props.location.pathname.split("/")[1];
    return (
      <div className="nav-bar">
        <div>
          <header>
            <a href="/">
              <img src={Logo} className="logo" alt="Logo"></img>
            </a>
          </header>
        </div>
        <div className="spacer"></div>
        <div className="nav-list">
          <nav
            className={
              activePath === "home" ? "nav-item nav-active" : "nav-item"
            }
          >
            <Link to="/home">
              <h4 className="label">Home</h4>
            </Link>
          </nav>
          <nav
            className={
              activePath === "about" ? "nav-item nav-active" : "nav-item"
            }
          >
            <Link to="/about">
              <h4 className="label">About Me</h4>
            </Link>
          </nav>
          <nav
            className={
              activePath === "resume" ? "nav-item nav-active" : "nav-item"
            }
          >
            <Link to="/resume">
              <h4 className="label">Resume</h4>
            </Link>
          </nav>
        </div>
      </div>
    );
  }
}

export default withRouter(NavBar);
