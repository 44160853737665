import React, { Component } from "react";
import Arrow from "../../assets/arrow-down.png";
import ShopperSpaceThumbnail from "../../assets/projects/shopperspace/thumbnail.png";
import airwireAppThumbnail from "../../assets/projects/airwire/app/thumbnail.png";
import airwireConsoleThumbnail from "../../assets/projects/airwire/console/thumbnail.png";
import greenifyThumbnail from "../../assets/projects/greenify/thumbnail.png";

export class HomePage extends Component {
  render() {
    return (
      <div>
        <section className="home-main">
          <article>
            <header>
              <h1 className="heading">Hello, Nice to see you</h1>
              <p className="description">
                I am Nithin S, A Passionate
                <div className="profation-container">
                  <ul>
                    <li className="high-light-txt">Product Designer.</li>
                  </ul>
                </div>
              </p>
            </header>
            <footer>
              <div className="scroll-down">
                <img className="icon" src={Arrow} alt="Arrow"></img>
                <p className="label">Scroll Down for Projects</p>
              </div>
            </footer>
          </article>
        </section>
        <div className="statment-container">
          <h4 className="statment-txt">
            "Products should be designed as a natural <br />
            Extension of individual"
          </h4>
        </div>
        <section className="projects-section" id="work" key="work">
          <article>
            <br></br>
            <header>
              <h2 className="heading">My Projects</h2>
            </header>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="projects-list">
              <article className="project-card">
                <a href="/work/airwire">
                  <header>
                    <img
                      className="project-img"
                      src={airwireAppThumbnail}
                      alt="ProjectImage"
                    ></img>
                  </header>
                  <div className="body">
                    <h2 className="title">Airwire App</h2>
                    <h3 className="sub-title">
                      Airwire broadband app for users to <br />
                      track data usage, recharge, Help center,
                      <br />
                      and more.
                    </h3>
                    <button className="more-btn">View Details</button>
                  </div>
                </a>
              </article>
              <article className="project-card">
                <a href="work/shopperspace">
                  <header>
                    <img
                      className="project-img"
                      src={ShopperSpaceThumbnail}
                      alt="ProjectImage"
                    ></img>
                  </header>
                  <div className="body">
                    <h2 className="title">Shopper Space</h2>
                    <h3 className="sub-title">
                      Grocery delivery from near-by <br />
                      favourite supermarkets
                    </h3>
                    <button className="more-btn">View Details</button>
                  </div>
                </a>
              </article>
              <article className="project-card">
                <a href="#">
                  <header>
                    <img
                      className="project-img"
                      src={greenifyThumbnail}
                      alt="ProjectImage"
                    ></img>
                  </header>
                  <div className="body">
                    <h2 className="title">Greenify</h2>
                    <h3 className="sub-title">
                      Online Plantation service in <br />
                      cities and towns.
                    </h3>
                    <button className="more-btn">Uploading Soon</button>
                  </div>
                </a>
              </article>
              <article className="project-card">
                <a href="#">
                  <header>
                    <img
                      className="project-img"
                      src={airwireConsoleThumbnail}
                      alt="ProjectImage"
                    ></img>
                  </header>
                  <div className="body">
                    <h2 className="title">Airwire Admin Console</h2>
                    <h3 className="sub-title">
                      Internal dashboard for the web & <br />
                      mobile interface for Airwire organisation, <br />
                      an Bengaluru based ISP.
                    </h3>
                    <button className="more-btn">Uploading Soon</button>
                  </div>
                </a>
              </article>
            </div>
          </article>
        </section>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default HomePage;
