import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <br />
        <div className="split">
          <div className="footer-txt">
            <p className="meata-text">GET IN TOUCH</p>
            <h1>
              <a className="mail" href="mailto:work@nithins.com">
                work@nithins.com
              </a>
            </h1>
            <p className="mail">Bengaluru, India.</p>
          </div>
          <div className="expanded"></div>
          <div>
            <p className="meata-text">LET'S CONNECT</p>
            <br />
            <div className="social-list">
              {/* <a
                className="social"
                href="https://www.behance.net/nithins3"
                target="_blank"
              >
                Behance
              </a> */}
              {/* <a
                className="social"
                href="https://www.instagram.com/nithin_0409/"
                target="_blank"
              >
                Instagram
              </a> */}
              {/* <a
                className="social"
                href="https://dribbble.com/nithins_0409/shots"
                target="_blank"
              >
                Dribble
              </a> */}
              <a
                className="social"
                href="https://www.linkedin.com/in/nithin-s-749729194/"
                target="_blank"
              >
                Linkedin
              </a>
              {/* <a
                className="social"
                href="https://twitter.com/NithinS36434050"
                target="_blank"
              >
                Twitter
              </a> */}
              {/* <a
                className="social"
                href="https://twitter.com/NithinS36434050"
                target="_blank"
              >
                Medium
              </a> */}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div className="split">
          <p>@2022 | All rights reserved to nithins.</p>
          <div className="expanded"></div>
          <p>Copyrights @2022</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
