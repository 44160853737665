import NavBar from "./app/component/navbar";
import Footer from "./app/component/footer";
import HomePage from "./app/home/home";
import About from "./app/home/about.jsx";
import Resume from "./app/home/resume";
import ShopperSpace from "./app/projects/shopperspace";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AirwireMobile from "./app/projects/airwire";
import AirwireProcess from "./app/projects/airwire/airwire_process";
import ReactGA from "react-ga";
import ShopperSpaceProcess from "./app/projects/shopperspace/shopper_space_process";
ReactGA.initialize("UA-245470321-1");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.event({
  category: "User",
  action: "Sent message",
});

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <Switch>
          <Redirect exact from="/" to="/home" />
          <Route exact path="/home">
            <HomePage />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/resume">
            <Resume />
          </Route>
          <Route exact path="/work/shopperspace">
            <ShopperSpace />
          </Route>
          <Route exact path="/work/airwire">
            <AirwireMobile />
          </Route>
          <Route exact path="/work/airwire/processbook">
            <AirwireProcess />
          </Route>
          <Route exact path="/work/shopperspace/processbook">
            <ShopperSpaceProcess />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
