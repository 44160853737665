import React, { Component } from "react";
import Airwire from "../../../assets/projects/airwire/app/AirwireProcess.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default class AirwireProcess extends Component {
  render() {
    return (
      <div className="project-container">
        <img className="project-img" src={Airwire} alt="Section1Image" />
        <br />
      </div>
    );
  }
}
