import React, { Component } from 'react'
import shopperspace from "../../../assets/projects/shopperspace/ShopperSpaceProcess.png";


export default class ShopperSpaceProcess extends Component {
    render() {
        return (
            <div className="project-container">
                <img className="project-img" src={shopperspace} alt="Section1Image" />
                <br />
            </div>
        )
    }
}
