import React, { Component } from "react";
import ResumeImage from "../../assets/resume/resume.png";
import ResumePdf from "../../assets/resume/nithins.pdf";

export class Resume extends Component {
  render() {
    return (
      <div>
        <section>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <img src={ResumeImage} alt="AboutImage" className="resume-img" />
          <br />
          <br />
          <a href={ResumePdf} download>
            <button className="download-btn">Download Resume</button>
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
        </section>
      </div>
    );
  }
}

export default Resume;
