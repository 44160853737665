import React, { Component } from "react";
import Airwire from "../../assets/projects/airwire/app/Airwire.png";
import thumbnail from "../../assets/projects/airwire/app/thumbnail.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export class AirwireMobile extends Component {
  render() {
    return (
      <div className="project-container">
        <br />
        <br />
        <br />
        <br />
        <br />
        <LazyLoadImage
          alt="project"
          className="project-img"
          PlaceholderSrc={thumbnail}
          effect="blur"
          src={Airwire} // use normal <img> attributes as props
        />
        <br />
        <a href="/work/airwire/processbook">
          <button className="read-more">View complete project process</button>
        </a>
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default AirwireMobile;
