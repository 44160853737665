import React, { Component } from "react";
import AboutImg1 from "../.././assets/about/about_img_1.png";
import AboutImg2 from "../.././assets/about/about_img_2.png";
import AboutImg3 from "../.././assets/about/about_img_3.png";
import Arrow from "../../assets/about/right-arrow.png";

// import arrowIcon from "../.././assets/about/arrow-down.png";

export class About extends Component {
  render() {
    return (
      <div className="about">
        <h3 className="heading">About Me</h3>
        <section className="content-section profile">
          <div className="content">
            <h2 className="title">Who am I?</h2>
            <p className="description">
              My name is nithin s and I’m a product designer <br />
              based in Bengaluru. A self taught designer with <br />
              computer science engineering background.
            </p>
            <div className="content scroll-down-h">
              <p className="description">
                <span>Scroll down</span>
              </p>
              <img src={Arrow} alt="Nithins Image" className="arrow-icon" />
            </div>
          </div>
          <img src={AboutImg1} alt="Nithins Image" className="display-image" />
        </section>
        <br></br>
        <br></br>
        <section className="content-section">
          <div className="content">
            <h2 className="title">What do I do?</h2>
            <p className="description">
              I am a technology geek and a person who loves minimalistic design.
              I was part of startup eco-system and also I bring diverse culture
              that influences my design. My background in Computer Science also
              helps me look at design from a technical point of view in addition
              to advocating for user’s needs and desires.
            </p>
          </div>
          <img src={AboutImg2} alt="Working emoji" className="display-image" />
        </section>
        <br></br>
        <br></br>
        <section className="content-section">
          <div className="content">
            <h2 className="title">Why I love design?</h2>
            <p className="description">
              I’ve always admired how “Thoughtful design” has the power to
              change our world, Whether it’s through simplifying one interaction
              or giving users an opportunity to view things through a new
              perspective.{" "}
            </p>
            <br />
            <p className="description">
              also overwhelmed by the fact that how a design of a digital
              product or physical product can change the way Individuals see the
              problems.{" "}
            </p>
          </div>
          <img src={AboutImg3} alt="Working emoji" className="display-image" />
        </section>
        <br></br>
        <br></br>
      </div>
    );
  }
}

export default About;
