import React, { Component } from "react";
import CompetativeAnalysis from "../../assets/projects/shopperspace/competativeanalysis.png";
import ShopperSpaceImage from "../../assets/projects/shopperspace/ShopperSpace.png";
import Hero2 from "../../assets/projects/shopperspace/hero-2.png";
import Sec3Art1 from "../../assets/projects/shopperspace/section_3_art_1.png";
import Sec3Art2 from "../../assets/projects/shopperspace/section_3_art_2.png";
import Sec3Art3 from "../../assets/projects/shopperspace/section_3_art_3.png";
import StoryBoard from "../../assets/projects/shopperspace/story_board.png";
import InfoArch from "../../assets/projects/shopperspace/info_arch.png";
import UserFlow from "../../assets/projects/shopperspace/user_flow.png";
import LowFedality from "../../assets/projects/shopperspace/low_fadelity.png";
import HighFedality from "../../assets/projects/shopperspace/high_fadelity.png";
import StyleSheet from "../../assets/projects/shopperspace/style_sheet.png";
import UserPersona from "../../assets/projects/shopperspace/user_persona.png";
import QuantityResearch from "../../assets/projects/shopperspace/auality_research.png";
import UsabilityStudy from "../../assets/projects/shopperspace/usability_study.png";
import UserJourney from "../../assets/projects/shopperspace/user_journey.png";
import AfinityDiagram from "../../assets/projects/shopperspace/afinity_diagram.png";
import DesignIteration from "../../assets/projects/shopperspace/design_iteration.png";
import FinalDesign1 from "../../assets/projects/shopperspace/final_design_1.png";
import FinalDesign2 from "../../assets/projects/shopperspace/final_design_2.png";


export class ShopperSpace extends Component {
  render() {
    return (
      <div className="project-container">
        <br></br>
        <br></br>
        <br></br>
        <img className="project-img" src={ShopperSpaceImage} alt="Section1Image" />
        <br />
        <a href="/work/shopperspace/processbook">
          <button className="read-more">View complete project process</button>
        </a>
        <br />
        <br />
      </div>
    );
  }
}

export default ShopperSpace;
